import React, { Component } from 'react';
import './login.scss';
import LoginManager from '../../services/authentication/login-manager';
import { Link } from 'react-router-dom';

class Login extends Component<{loginManager: LoginManager}, {username: string, password: string}> {

  constructor(props: any){
    super(props);
    this.state = {
      username: "",
      password: ""
    }
    this.signIn = this.signIn.bind(this);
  }
  signIn(event: React.FormEvent<HTMLFormElement>){
    event.preventDefault();
    this.props.loginManager._loginWithEmail(this.state.username, this.state.password);
  }
  render() {
    return (
      <div className="login-container">
        <form className="login-form card" onSubmit={this.signIn} >
          <h2>Login</h2>
          <div className="fb-login-button" data-max-rows="1" data-size="large" data-button-type="login_with" data-show-faces="false" data-auto-logout-link="false" data-use-continue-as="false"></div>
          <div className="input-field">
            <input id="username" type="text" value={this.state.username} onChange={e => {this.setState({username: e.target.value})}} />
            <label>Username</label>
          </div>
          <div className="input-field">
            <input id="password" type="password"  value={this.state.password} onChange={e => {this.setState({password: e.target.value})}} />
            <label>Password</label>
          </div>
          <button className="btn">Login</button>
          <div className="sign-up-container">
            <p>Or click <Link to="/sign-up">here</Link> to sign up!</p>
          </div>
        </form>
      </div>
    );
  }
}

export default Login;
