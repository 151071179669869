import React, { Component, Fragment } from 'react';
import './brand-awareness.scss';
import ICompany from '../../models/ICompany';
import IBrandAwareness from '../../models/IBrandAwareness';
import { Line } from 'react-chartjs-2';
import LoadingCircle from '../loading-circle/loading-circle';
import ReportHTTP from '../../services/http/reportHttp';
class BrandAwareness extends Component<{ company: ICompany }, { brandAwareness: IBrandAwareness | null, isRequesting: boolean }> {

  httpService: ReportHTTP
  constructor(props: any) {
    super(props);
    this.httpService = new ReportHTTP();
    this.state = {
      isRequesting: false,
      brandAwareness: null
      
    }
  }

  componentDidMount(){
    this.getPlots(this.props.company);
  }
  getPlots(company: ICompany) {
    this.httpService.getBrandAwareness(company).then(res => {
      this.setState({
        brandAwareness: res
      })
    })
  }
  render() {
    console.log(this.state);
    let data = {
      labels: this.state.brandAwareness ? this.state.brandAwareness.plots.map(seg => seg.date.toDateString()) : [],
      datasets: [
        {
          data: this.state.brandAwareness ? this.state.brandAwareness.plots.map(seg => seg.point) : [],
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ]
        }]
    };
    return (
      <div className="BrandAwareness">
        <h2 className="header">Brand and Awareness</h2>
        <div className="percentage-item total-awareness">
          <div className="percentage">{this.state.brandAwareness ? this.state.brandAwareness.totalChange : 0}%</div>
          <div className="text">total brand awareness</div>
        </div>
        <div className="percentage-item month-awareness">
          <div className="percentage">{this.state.brandAwareness ? this.state.brandAwareness.monthChange : 0}%</div>
          <div className="text">1-month chnage in awareness</div>
        </div>
        <div className="chart">
          <Fragment>
            <LoadingCircle showSpinner={this.state.isRequesting} />
            {
              !this.state.isRequesting ?
                this.state.brandAwareness && this.state.brandAwareness.plots.length > 0 ?
                  <Line data={data} />
                  : 'No data'
                : ''
            }
          </Fragment>
        </div>
      </div>
    )
  }
}
export default BrandAwareness;