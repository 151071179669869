import React, { Component } from 'react';
import { UserContext } from '../../contexts/userContext';
import './reports.scss';
class Reports extends Component<{}, {}>{
  render() {
    let myReports: Element[] = [];
    let cannapanelReports: Element[] = [];
    let monthlyUpdateReports: Element[] = [];
    let specialReports: Element[] = [];
    let yearlyComprehensiveReports: Element[] = [];
    return (
      <UserContext.Consumer>
        {({ isAdmin, navigateToPage }) => (
          isAdmin ?
            <div className="Reporting">
              <article className="my-reports">
                <h2 className="header">My Reports</h2>
                <div className="container">
                  {
                    myReports
                  }
                </div>
              </article>
              <article className="latest-reports">
              <h2 className="header">Latest Cannapanel Report</h2>
                <div className="container">
                  {
                    cannapanelReports
                  }
                </div>
              </article>
              <article className="monthly-updates">
              <h2 className="header">Montly Updates</h2>
                <div className="container">
                  {
                    monthlyUpdateReports
                  }
                </div>
              </article>
              <article className="special-reports">
              <h2 className="header">Special Reports</h2>
                <div className="container">
                  {
                    specialReports
                  }
                </div>
              </article>
              <article className="yearly-comprehensive-reports">
              <h2 className="header">2017 Comprehensive and State Reports</h2>
                <div className="container">
                  {
                    yearlyComprehensiveReports
                  }
                </div>
              </article>
            </div>
            : ''
        )}
      </UserContext.Consumer>
    )
  }
}
export default Reports;