import HttpManager from "./httpManager";
import IMail from "../../models/IMail";

class IMailHTTP {
  static ROOT_URL = "mail/";
  getHeaders(): {} {
    var headers = HttpManager.attachHeaders();
    return headers;
  }

  createIMail(mail: IMail): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${IMailHTTP.ROOT_URL}`,
        {
          headers: this.getHeaders(),
          method: 'POST',
          body: JSON.stringify(mail)
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }
}

export default IMailHTTP;