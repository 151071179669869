import React, { Component } from 'react';
import './customer-surveys.scss';
import { Collapsible, CollapsibleItem } from 'react-materialize';
import ProductPackages from '../../components/product-packages/product-packages';
class CustomerSurveys extends Component<{}, {}> {

  render() {
    return (
      <div className="customer-surveys-container">
        <section className="gga-section jumbotron">
          <div className="jumbotron-header">
            <h1>How Can I Learn About My Customers?</h1>
            <h2>Ask them!</h2>
          </div>
        </section>
        <section className="gga-section section-2">
          <h2>Why Survey Customers?</h2>
          <p>Today’s consumers crave interaction with their favorite brands, and giving them a chance to share their feedback with you is a win-win. Customer surveys can give you data and insights that are simply impossible to find elsewhere.</p>
          <p>Here are some of the topics we have helped our clients learn more about.</p>
          <Collapsible>
            <CollapsibleItem header="Customer Segmentation" icon="add_circle_outline" expanded="true">
              <p>Customer segmentation and profiling forms the basis of any market research effort. For example, only one in ten recreational cannabis customers regularly purchases topicals and creams. But they form a distinctive group that also favors CBD-rich products and edibles, has a much higher income, wants a large product selection, and almost never buy concentrates or pre-rolls. What kind of customers do you have?</p>
            </CollapsibleItem>
            <CollapsibleItem header="Purchasing Decisions" icon="add_circle_outline">
              <p>Why do so many consumers still choose a product based on the THC percentage? Simple: they just do not have enough information. Our research shows those with a higher level of education tend to choose on other factors. How can you educate your customers, and which ones will be most likely to try new and upmarket products?</p>
            </CollapsibleItem>
            <CollapsibleItem header="Marketing Preferences" icon="add_circle_outline">
              <p>Email, text message, or Instagram? Cannabis consumers are cautious about publicly engaging with brands on social media, especially those in the Northeast and in older age groups. So, what is the best way to stay in front of your customers without bombarding them? What are the most promising channels for growing your customer base?</p>
            </CollapsibleItem>
            <CollapsibleItem header="Shopping and Loyalty" icon="add_circle_outline">
              <p>Although recreational cannabis users are most likely to choose dispensary based on location and price, the highest-spending customers have a different set of priorities. What drives customers to your store or brand? What keeps them away?</p>
            </CollapsibleItem>
            <CollapsibleItem header="Product Preferences" icon="add_circle_outline">
              <p>Most dispensaries do not stock a wide enough variety of CBD-rich products for high-spending customers. Is your mix of cannabis products right for your customers, or are you missing a critical opportunity? Is there room for growth in new product segments?</p>
            </CollapsibleItem>
            <CollapsibleItem header="Customer Experience" icon="add_circle_outline">
              <p>What is the first thing a customer smells when they open up your product? That might be easy, but how about what they think? How does it compare with other products or dispensaries? How could the experience be improved?</p>
            </CollapsibleItem>
          </Collapsible>
        </section>
        <section className="gga-section section-3">
          <h2>Choosing Green Growth Analytics</h2>
          <p>Sending out a survey can be easy, but asking the perfect questions and teasing the answers from your data is not. Fortunately, our team has been designing, deploying, and analyzing surveys for years - we know exactly what to ask!</p>
          <p>Working with an experienced team means avoiding rookie mistakes that can invalidate a whole survey. It means not thinking <i>I wish we’d asked that question</i> or <i>Why didn’t we phrase it like that?</i> It means advanced data analysis techniques like neural nets that draw every bit of insight out of your data. And most importantly, it means the results of the survey will be presented in a clear and comprehensive way that you can use immediately to improve your decision-making.</p>
          <p>Our exclusive focus on cannabis allows us benchmark your results, both against others in the industry and against the results of our own research and surveys. We use proprietary data and models to identify the key characteristics of your customer base and make predictions about behavior. Because the cannabis sector is so unique, outside companies are unlikely to be familiar with cannabis-related terminology, let alone the ins and outs of your business.</p>
        </section>
        <section className="gga-section section-4">
          <ProductPackages />
        </section>
      </div>
    );
  }
}

export default CustomerSurveys;
