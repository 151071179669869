import HttpManager from "./httpManager";
import IUser from '../../models/IUser';
class UserHTTP {
  static ROOT_URL = "users/";
  getHeaders(): {} {
    var headers = HttpManager.attachHeaders();
    return headers;
  }
  getUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${UserHTTP.ROOT_URL}`,
        {
          headers: this.getHeaders(),
          method: 'GET'
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  getUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${UserHTTP.ROOT_URL}`,
        {
          headers: this.getHeaders(),
          method: 'GET'
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  createUser(user: IUser): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${UserHTTP.ROOT_URL}`,
        {
          headers: this.getHeaders(),
          method: 'POST',
          body: JSON.stringify(user)
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  editUser(blog: IUser): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${UserHTTP.ROOT_URL}`,
        {
          headers: this.getHeaders(),
          method: 'PUT'
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  deleteUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${UserHTTP.ROOT_URL}`,
        {
          headers: this.getHeaders(),
          method: 'DELETE'
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }
}

export default UserHTTP;