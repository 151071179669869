import React, { Component } from 'react';
import './blog-post-summary.scss';
import IBlog from '../../models/IBlog';
import { Link } from 'react-router-dom';
import blog from '../../pages/blogs/blog';

class BlogPostSummary extends Component<{ blog: IBlog }, {}> {
  render() {
    let summary = this.props.blog.summary? this.props.blog.summary : '';
    return (
      <article className="blog-post card">
        <Link to={`/blog/${this.props.blog.path}`}>{this.props.blog.title}</Link>
        <div className="blog-summary" dangerouslySetInnerHTML={ {__html: summary}}></div>
        {
          !this.props.blog.isPublished?
            <div>Not published</div>
          :''
        }
        <Link to={`/blog/${this.props.blog.path}`}>Read More...</Link>
      </article>
    );
  }
}

export default BlogPostSummary;
