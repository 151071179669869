import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './landing.scss';
import {Row, Col, Card} from 'react-materialize';
import EmailListSubmit from '../../components/email-list-submit/email-list-submit';

class Landing extends Component {
  render() {
    return (
      <div className="landing-page-container">
        <section className="gga-section jumbotron">
          <div className="jumbotron-header">
            <h1>Consumer and Market Research.</h1>
            <h2>For the Cannabis Industry.</h2>
          </div>
        </section>
        <section className="gga-section about-gga">
          <h2>What Can Green Growth Do for You?</h2>
          <p>The team at Green Growth Analytics is focused on two things: <u>understanding your customers</u> and <u>understanding your market</u>.</p>
          <Row>
            <Col m={4} s={12}>
              <Card
                className="blue-grey lighten-2 hoverable"
                textClassName="white-text"
                title="Customer Surveys"
                header={<img src="/assets/images/customer-survey.png" className="card-image"/>}
                actions={[<a href="/customer-surveys">Learn More</a>]}
              >
                  “Know thy customer.” We can help.
              </Card>
            </Col>
            <Col m={4} s={12}>
              <Card
                className="blue-grey hoverable"
                textClassName="white-text"
                title="Market Studies"
                header={<img src="/assets/images/market-study.png" className="card-image"/>}
                actions={[<a href="/market-studies">Learn More</a>]}
              >
                The entire picture, backed by data.
              </Card>
            </Col>
            <Col m={4} s={12}>
              <Card
                className="blue-grey darken-2 hoverable"
                textClassName="white-text"
                title="Membership"
                header={<img src="/assets/images/membership.png" className="card-image"/>}
                actions={[<a href="/membership">Learn More</a>]}
              >
                Our market and consumer reports.
              </Card>
            </Col>
          </Row>
        </section>
        <div className="divider"></div>
        <section className="gga-section stay-in-touch">
          <Row>
            <Col s={12} l={6}>
              <h2>Stay in touch!</h2>
              <p>Get notified about new whitepapers, research, and more. We won’t email more than once or twice a week - promise!</p>
            </Col>
            <Col s={12} l={6}>
              <EmailListSubmit />
            </Col>
          </Row>
        </section>
        <div className="divider"></div>
        <section className="gga-section news-events">
          <h2>News and Events</h2>
          <div className="news-events-items">
            <div className="news-events-item">
              <h3>NoCo Hemp Expo 2019</h3>
              <p>Green Growth is coming to Denver from March 28 to April 1!</p>
            </div>
            <div className="news-events-item">
              <h3>New Whitepaper: Recreational Sales in Massachusetts vs. Others, and Implications for Michigan</h3>
              <p>Coming in April 2019.</p>
            </div>
            <div className="news-events-item">
              <h3>NECANN Boston 2019: Green Growth Will Be There</h3>
              <p>We had a great time from March 22 to 24 at the New England Cannabis Convention in Boston!</p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Landing;
