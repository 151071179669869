import React, { Component, Fragment } from 'react';
import './customer-segmentation.scss';
import ICompany from '../../models/ICompany';
import ICustomerSegmentation from '../../models/ICustomerSegmentation';
import { Pie, Doughnut } from 'react-chartjs-2';
import LoadingCircle from '../loading-circle/loading-circle';
import ReportHTTP from '../../services/http/reportHttp';

class CustomerSegmentation extends Component<{ company: ICompany }, { customerSegmentation: ICustomerSegmentation | null, isRequesting: boolean }> {

  httpService: ReportHTTP;
  constructor(props: any) {
    super(props);
    this.httpService = new ReportHTTP();
    this.state = {
      customerSegmentation: null,
      isRequesting: false
    }
  }

  componentDidMount() {
    this.getCompanySegment(this.props.company);
  }
  getCompanySegment(company: ICompany) {
    this.httpService.getCompanySegment(company).then(res => {
      this.setState({
        customerSegmentation: res
      })
    }).catch(err => console.log(err));
  }

  render() {
    let rightNowData = {
      labels: this.state.customerSegmentation ? this.state.customerSegmentation.rightNow.map(seg => seg.name) : [],
      datasets: [
        {
          data: this.state.customerSegmentation ? this.state.customerSegmentation.rightNow.map(seg => seg.percentage) : [],
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ]
        }]
    };
    let overallData = {
      labels: this.state.customerSegmentation ? this.state.customerSegmentation.overall.map(seg => seg.name) : [],
      datasets: [
        {
          data: this.state.customerSegmentation ? this.state.customerSegmentation.overall.map(seg => seg.percentage) : [],
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ]
        }]
    };
    return (
      <div className="CustomerSegmentation">
        <h2 className="header"> Customer Segmentation</h2>
        <div className="segment current">
          <h3 className="segment-header">Right Now</h3>
          <div className="pie-chart">
            {
              <Fragment>
                <LoadingCircle showSpinner={this.state.isRequesting} />
                {!this.state.isRequesting ?
                  this.state.customerSegmentation ?
                    <Pie data={rightNowData} />
                    : 'No data'
                  : ''}
              </Fragment>
            }
          </div>
        </div>
        <div className="segment overall">
          <h3 className="segment-header">Overall</h3>
          <div className="pie-chart">
            {
              <Fragment>
                <LoadingCircle showSpinner={this.state.isRequesting} />
                {!this.state.isRequesting ?
                  this.state.customerSegmentation ?
                    <Pie data={overallData} />
                    : 'No data'
                  : ''}
              </Fragment>
            }
          </div>
        </div>
        <div className="recommendation">
          <h3 className="title">Recommendation</h3>
          <ul className="list">
            <Fragment>
              <LoadingCircle showSpinner={this.state.isRequesting} />
              {!this.state.isRequesting ?
                this.state.customerSegmentation ?
                  this.state.customerSegmentation.recommendations.map((rec, i) => <li key={i} className="list-item">{rec}</li>)
                  : 'No recomendations'
                : ''
              }
            </Fragment>
          </ul>
        </div>
      </div>
    )
  }
}

export default CustomerSegmentation;