import React from 'react'
import { Formik } from 'formik'
import './email-list-submit.scss'


class EmailListSubmit extends React.Component<{}, {value: string}> {

  handleSubmit = (values, { 
    props = this.props, 
    setSubmitting 
  }) => {
    setTimeout(() => {
      alert("Thanks!")
      setSubmitting(false);
    }, 400);
    return;
  }

  render() {
    return (
      <div className="row">
        <div className="col">
          <div className="card-panel hoverable">
            <p>Enter your email address below to join our newsletter.</p>

            <Formik
              initialValues={{ email: ''}}
              enableReinitialize={false}
              onSubmit={this.handleSubmit}
              validate={values => {
                let errors:any = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                return errors;
              }}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="row align-center input-field">
                      <div className="col">
                        <i className="material-icons prefix">email</i>
                        <input
                          id="email"
                          placeholder="Enter your email"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="validate" />
                        {errors.email && touched.email && (
                          <span className="input-feedback helper-text">{errors.email}</span>
                        )}
                      </div>

                      <div className="col">
                        <button type="submit" className="btn" disabled={isSubmitting}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

export default EmailListSubmit