import React, { Component } from 'react';
import { UserContext } from '../../contexts/userContext';
import './cannapanel.scss';
import StoryInfoSummary from '../../components/story-info-summary/story-info-summary';
import { CompanyContext } from '../../contexts/companyContext';
import Chart from 'chart.js';
import BrandAwareness from '../../components/brand-awareness/brand-awareness';
import CustomerSegmentation from '../../components/customer-segmentation/customer-segmentation';
import Forecasting from '../../components/forecasting/forecasting';
import ICompany from '../../models/ICompany';
import ReportHTTP from '../../services/http/reportHttp';
import CompanyReporting from '../../models/ICompanyReporting';

function Cannapanel(props: any) {
  return (
    <UserContext.Consumer>
      {({ isAdmin, navigateToPage }) => (
        isAdmin ?
          <CompanyContext.Consumer>
            {({ isRequesting, companies }) => (
              isRequesting ?
                <div>It is loading</div>
                :
                <InnerCannapanel companies={companies} />
            )}
          </CompanyContext.Consumer>
          : ''
      )}
    </UserContext.Consumer>
  )
}
class InnerCannapanel extends Component<{ companies: ICompany[] }, { companyDetails: CompanyReporting | null }>{

  httpService: ReportHTTP

  constructor(props: any) {
    super(props);
    this.httpService = new ReportHTTP();
    this.state = {
      companyDetails: null
    }
  }

  componentDidMount() {
    this.getCompanyDetails(this.props.companies[0]);
  }

  getCompanyDetails(company: ICompany) {
    this.httpService.getCompanyDetails(company).then(res => {
      this.setState({
        companyDetails: res
      })
    }).catch(err => console.log(err));
  }
  render() {
    return (
      <div className="Cannapanel-container">
        <article className="story-info">
          {this.state.companyDetails ?
            <StoryInfoSummary company={this.state.companyDetails} />
            : ''}
        </article>
        <article className="brand-awareness">
          <BrandAwareness company={this.props.companies[0]} />
        </article>
        <article className="customer-segment">
          <CustomerSegmentation company={this.props.companies[0]} />
        </article>
        <article className="forecasting">
          <Forecasting company={this.props.companies[0]} />
        </article>
      </div>
    )
  }
}
export default Cannapanel;