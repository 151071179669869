import React, {Component} from 'react';
import './story-info-summary.scss';
import ICompanyReporting from '../../models/ICompanyReporting';

class StoryInfoSummary extends Component<{company: ICompanyReporting},{}> {
  render(){
    return (
      <div className="StoryInfoSummary">
        <h2 className="title">{this.props.company.name}</h2>
        <img src={this.props.company.imageUrl} className="image"></img>
        <div className="description">{this.props.company.description}</div>
      </div>
    )
  }
}

export default StoryInfoSummary;