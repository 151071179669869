import HttpManager from "./httpManager";
import IReport from "../../models/IReport";
import ICompany from "../../models/ICompany";
import IAnalysis from "../../models/IAnalysis";
import IBrandAwareness from "../../models/IBrandAwareness";
import ICustomerSegmentation from "../../models/ICustomerSegmentation";
import ISegment from "../../models/ISegment";
import CompanyReporting from "../../models/ICompanyReporting";

class ReportHTTP {
  static ROOT_URL = "reports/";
  getHeaders(): {} {
    var headers = HttpManager.attachHeaders();
    return headers;
  }
  getReports(): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = `${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${ReportHTTP.ROOT_URL}`;
      fetch(url,
        {
          headers: this.getHeaders(),
          method: 'GET'
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  getReport(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!id) {
        reject({});
      }
      console.log(id);
      let url = `${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${ReportHTTP.ROOT_URL}${id}`;
      fetch(url,
        {
          headers: this.getHeaders(),
          method: 'GET'
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  createReport(report: IReport): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${ReportHTTP.ROOT_URL}`,
        {
          headers: this.getHeaders(),
          method: 'POST',
          body: JSON.stringify(report)
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  editReport(report: IReport): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${ReportHTTP.ROOT_URL}${report.id}`,
        {
          headers: this.getHeaders(),
          method: 'PUT',
          body: JSON.stringify(report)
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  deleteReport(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!id) {
        reject({});
      }
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${ReportHTTP.ROOT_URL}${id}`,
        {
          headers: this.getHeaders(),
          method: 'DELETE'
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  getCompanyReport(company: ICompany) : Promise<IAnalysis>{
    return new Promise((resolve, reject) => {
      resolve({
          name: "Germangee",
          awarenessPct: 46,
          pricingAnalysis: 7,
          grade: {
            overall: 10,
            flower: 2,
            edibles: 6
          },
          inventoryPricing: [{
            name: "Zonal Geranium",
            type: "flower",
            price: 80
          }, {
            name: "Carolina Horsenettle",
            type: "flower",
            price: 84
          }, {
            name: "Stingbush",
            type: "flower",
            price: 12
          }, {
            name: "Royal Star Orchid",
            type: "flower",
            price: 21
          }],
          overview: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi, placeat eum eligendi quis voluptatum, quidem mollitia, dicta veniam voluptatem eius perspiciatis. Quia mollitia, delectus minima modi dolorum voluptatibus asperiores laboriosam."
        })
    })
  }
  getCompetitorReports(company: ICompany): Promise<IAnalysis[]>{
    return new Promise((resolve, reject) => {
      resolve([{
        name: "InnoZ",
        awarenessPct: 61,
        pricingAnalysis: 1,
        grade: {
          overall: 2,
          flower: 4,
          edibles: 5
        },
        inventoryPricing: [{
          name: "Zonal Geranium",
          type: "flower",
          price: 80
        }, {
          name: "Carolina Horsenettle",
          type: "flower",
          price: 84
        }, {
          name: "Stingbush",
          type: "flower",
          price: 12
        }, {
          name: "Royal Star Orchid",
          type: "flower",
          price: 21
        }],
        overview: "1Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi, placeat eum eligendi quis voluptatum, quidem mollitia, dicta veniam voluptatem eius perspiciatis. Quia mollitia, delectus minima modi dolorum voluptatibus asperiores laboriosam."
      }, {
        name: "Thoughtstorm",
        awarenessPct: 56,
        pricingAnalysis: 2,
        grade: {
          overall: 6,
          flower: 5,
          edibles: 5
        },
        inventoryPricing: [{
          name: "Zonal Geranium",
          type: "flower",
          price: 80
        }, {
          name: "Carolina Horsenettle",
          type: "flower",
          price: 84
        }, {
          name: "Stingbush",
          type: "flower",
          price: 12
        }, {
          name: "Royal Star Orchid",
          type: "flower",
          price: 21
        }],
        overview: "2Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi, placeat eum eligendi quis voluptatum, quidem mollitia, dicta veniam voluptatem eius perspiciatis. Quia mollitia, delectus minima modi dolorum voluptatibus asperiores laboriosam."
      }, {
        name: "Blogpad",
        awarenessPct: 49,
        pricingAnalysis: 10,
        grade: {
          overall: 4,
          flower: 5,
          edibles: 9
        },
        inventoryPricing: [{
          name: "Zonal Geranium",
          type: "flower",
          price: 80
        }, {
          name: "Carolina Horsenettle",
          type: "flower",
          price: 84
        }, {
          name: "Stingbush",
          type: "flower",
          price: 12
        }, {
          name: "Royal Star Orchid",
          type: "flower",
          price: 21
        }],
        overview: "3Lorem ipsum dolor, sit amet consectetur adipisicing elit. Animi, placeat eum eligendi quis voluptatum, quidem mollitia, dicta veniam voluptatem eius perspiciatis. Quia mollitia, delectus minima modi dolorum voluptatibus asperiores laboriosam."
      }])
    })
  }
  getBrandAwareness(company: ICompany): Promise<IBrandAwareness>{
    return new Promise((resolve, reject) => {
      resolve({
        totalChange: 12,
        monthChange: 3.2,
        plots: []
      })
    })
  }

  getCompanySegment(company: ICompany): Promise< ICustomerSegmentation> {
    return new Promise((resolve, reject) => {
      resolve({
        recommendations: ['Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis eos sed distinctio velit facilis magni, sint, tempora repellat deleniti aperiam eum. Atque aperiam temporibus commodi consequuntur at reprehenderit, harum pariatur?', 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis eos sed distinctio velit facilis magni, sint, tempora repellat deleniti aperiam eum. Atque aperiam temporibus commodi consequuntur at reprehenderit, harum pariatur?', 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis eos sed distinctio velit facilis magni, sint, tempora repellat deleniti aperiam eum. Atque aperiam temporibus commodi consequuntur at reprehenderit, harum pariatur?'],
        rightNow: [{ name: "Good year", percentage: 10 }, { name: "Bad year", percentage: 60 }, { name: "Fantastic year", percentage: 30 }],
        overall: [{ name: "Good year", percentage: 10 }, { name: "Bad year", percentage: 60 }, { name: "Fantastic year", percentage: 30 }],
      })
    })
  }

  getForecastingData(company: ICompany): Promise< ISegment[]> {
    return new Promise((resolve, reject) => {
      resolve([]);
    })
  }

  getCompanyDetails(company: ICompany): Promise<CompanyReporting> {
    return new Promise((resolve, reject) => {
      resolve({
        name: company.name,
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio corrupti recusandae fugiat ad animi dolore, doloribus veniam laudantium similique nesciunt aspernatur illo, fugit maiores molestias vitae aliquam facere? Corporis, dignissimos?",
        imageUrl: "/assets/images/logo.png"
      })
    })
  }
}

export default ReportHTTP;