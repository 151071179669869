import React, { Component } from 'react';
import './sign-up.scss';
import IMailHTTP from '../../services/http/mailHttp';
import IMail from '../../models/IMail';
class SignUp extends Component<{}, { name: string, email: string, message: string, nameError: boolean, emailError: boolean, subjectError: boolean, messageError: boolean }> {

  httpService: IMailHTTP;
  constructor(props: any) {
    super(props);
    this.httpService = new IMailHTTP();
    this.state = {
      name: "",
      email: "",
      message: "",
      nameError: false,
      emailError: false,
      messageError: false,
      subjectError: false
    }
    this.sendMessage = this.sendMessage.bind(this);
  }

  sendMessage() {
    let email: IMail = {
      name: this.state.name,
      email: this.state.email,
      subject: "Sign me up",
      body: this.state.message
    }
    this.httpService.createIMail(email).then(_ => {
      console.log("successful")
    }).catch(err => {
      console.log("unsucessful")
    })
  }
  render() {
    return (
      <div className="SignUp">
        <div className="contact-container">
          <form className="card card--email">
            <h2>Reach out to us!</h2>
            <div className="form-group input-field">
              <input type="text" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
              <label>Your name</label>
            </div>
            <div className="form-group input-field">
              <input type="email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
              <label>Your email</label>
            </div>
            <div className="form-group input-field">
              <textarea className="form-body materialize-textarea" name="message" value={this.state.message} onChange={e => this.setState({ message: e.target.value })} ></textarea>
              <label>Message Body (optional)</label>
            </div>
            <button type="submit" className="btn" onClick={this.sendMessage}>Send message</button>
          </form>
        </div>
      </div>
    );
  }
}

export default SignUp;
