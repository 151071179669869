import React, { Component } from "react";
import './loading-circle.scss';

class LoadingCircle extends Component<{ showSpinner: boolean }, {}> {
  render() {
    return (
      <div className="loading-circle-container">
        {
          this.props.showSpinner ?
            <div className="preloader-wrapper big active">
              <div className="spinner-layer spinner-blue">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div><div className="gap-patch">
                  <div className="circle"></div>
                </div><div className="circle-clipper right">
                  <div className="circle"></div>
                </div>
              </div>
            </div> : ''
        }
      </div>
    )
  }
}

export default LoadingCircle;