import React, { Component } from 'react';
import './no-match.scss';

class NoMatch extends Component {
  render() {
    return (
      <div className="NoMatch">
      Not Found 
      </div>
    );
  }
}

export default NoMatch;
