import React, {Component} from 'react';
import './pricing-analysis.scss';
import IAnalysis from '../../models/IAnalysis';

class PricingAnalysis extends Component<{competitors: IAnalysis[], client: IAnalysis| null}, {}> {

  render(){
    return (
      <div className="PricingAnalysis">
        <h2 className="header">Pricing Analysis</h2>
        <div className="line-graph"></div>
        <div className="grade">
          <div className="name">Overall</div>
          <div className="value">{this.props.client? this.props.client.grade.overall: "?"}</div>
          <div className="name">Flower</div>
          <div className="value">{this.props.client? this.props.client.grade.flower: "?"}</div>
          <div className="name">Edibles</div>
          <div className="value">{this.props.client? this.props.client.grade.edibles : "?"}</div>
        </div>
      </div>
    )
  }
}

export default PricingAnalysis;