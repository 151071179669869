import React, { Component, Fragment } from 'react';
import { Row, Col, Card, Modal, Button } from 'react-materialize';
import './product-packages.scss';
class ProductPackages extends Component<{}, {}> {
  navigateToContactUs() {
    window.location.pathname = "/contact-us"
  }
  render() {
    return (
      <Fragment>

        <h2>Pricing and Packages</h2>
        <p>Choose from our three standard customer survey packages below, or get in touch to request a custom quote.</p>
        <Row className="product-cards">
          <Col m={4} s={12}>
            <Card
              className="blue-grey darken-1"
              textClassName="white-text"
              title="Getting to Know You"
              actions={[<Modal header="Getting to Like you" trigger={<a >Learn More</a>}>
                <ul className="packages-list">
                  <li>Up to 500 Customer responses analyzed</li>
                  <li>15 survey questions</li>
                  <li>Analysis of 5 of your customer profiles</li>
                  <li>3 free-response questions on survey</li>
                  <li>1 in-depth demographic factors</li>
                </ul>
                <Button onClick={this.navigateToContactUs}>Contact Us for more info</Button>
              </Modal>]}              >
              <div className="card-section">
                Start to understand who is your customer
            </div>

            </Card>
          </Col>
          <Col m={4} s={12}>
            <Card
              className="blue-grey darken-1"
              textClassName="white-text"
              title="Getting to Like You"
              actions={[<Modal header="Getting to Like you" trigger={<a >Learn More</a>}>
                <ul className="packages-list">
                  <li>Up to 1,000 Customer responses analyzed</li>
                  <li>Analysis of 8 of your customer profiles</li>
                  <li>5 free-response questions on survey</li>
                  <li>3 in-depth demographic factors</li>
                  <li>2 competitor profiles</li>
                  <li>Benchmark your data with 5 of our consumer profiles</li>
                </ul>
                <Button onClick={this.navigateToContactUs}>Contact Us for more info</Button>
              </Modal>]}
            >
              What is your reputation? And how about your competitors?
          </Card>
          </Col>
          <Col m={4} s={12}>
            <Card
              className="blue-grey darken-1"
              textClassName="white-text"
              title="Customer Deep Dive"
              actions={[<Modal header="Customer Deep Dive" trigger={<a >Learn More</a>}>
                <ul className="packages-list">
                  <li>Up to 2,000 Customer responses analyzed</li>
                  <li>Analysis of 12 of your customer profiles</li>
                  <li>40 survey questions</li>
                  <li>10 free-response questions on survey</li>
                  <li>5 in-depth demographic factors</li>
                  <li>2 competitor profiles</li>
                  <li>Benchmark your data with unlimited access to our consumer profiles</li>
                  <li>Key insight to underserved consumers</li>
                </ul>
                <Button onClick={this.navigateToContactUs}>Contact Us for more info</Button>
              </Modal>]}              >
              Exclusive reporting on cannabis markets and consumers. Stay ahead of the curve.
          </Card>
          </Col>
        </Row>
      </Fragment>
    )
  }
}

export default ProductPackages;