import React, {Component} from 'react';
import ICompany from '../../models/ICompany';
import LoadingCircle from '../loading-circle/loading-circle';
import IAnalysis from '../../models/IAnalysis';
import './awareness.scss';

class Awareness extends Component<{competitors: IAnalysis[], client: IAnalysis| null}, {isRequesting: boolean, }> {

  constructor(props: any){
    super(props);
    this.state ={
      isRequesting: false,
    }
  }

  componentDidMount(){
    this.getAwareness()
  }

  getAwareness(){

  }
  render(){
    var companyList = this.props.competitors.slice();
    this.props.client ? companyList.push(this.props.client):'';
    let list = companyList.sort((a, b) => b.awarenessPct -a.awarenessPct).map((company, i) => <li key={i} className={this.props.client && this.props.client.name === company.name ?"bold list-item":'list-item'}>{company.name} {company.awarenessPct}%</li>);
    return (
      <div className="Awareness">
        <h2 className="header">Awareness</h2>
        {
          this.state.isRequesting ?
          <LoadingCircle showSpinner={this.state.isRequesting} />
          :
          companyList.length > 0 ?
            <ol className="competitors">
              {list}
            </ol>
          : 'No data'
        }
      </div>
    )
  }
}

export default Awareness;