import HttpManager from "./httpManager";
import IBlog from "../../models/IBlog";

class BlogHTTP {
  static ROOT_URL = "blogs/";
  getHeaders(): {} {
    var headers = HttpManager.attachHeaders();
    return headers;
  }
  getBlogs(): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = `${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${BlogHTTP.ROOT_URL}`;
      fetch(url,
        {
          headers: this.getHeaders(),
          method: 'GET'
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  getBlog(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!id) {
        reject({});
      }
      console.log(id);
      let url = `${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${BlogHTTP.ROOT_URL}${id}`;
      fetch(url,
        {
          headers: this.getHeaders(),
          method: 'GET'
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  createBlog(blog: IBlog): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${BlogHTTP.ROOT_URL}`,
        {
          headers: this.getHeaders(),
          method: 'POST',
          body: JSON.stringify(blog)
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  editBlog(blog: IBlog): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${BlogHTTP.ROOT_URL}${blog.path}`,
        {
          headers: this.getHeaders(),
          method: 'PUT',
          body: JSON.stringify(blog)
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  deleteBlog(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!id) {
        reject({});
      }
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${BlogHTTP.ROOT_URL}${id}`,
        {
          headers: this.getHeaders(),
          method: 'DELETE'
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }
}

export default BlogHTTP;