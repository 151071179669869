import React, { Component, Fragment } from 'react';
import './forecasting.scss';
import ICompany from '../../models/ICompany';
import { Line } from 'react-chartjs-2';
import LoadingCircle from '../loading-circle/loading-circle';
import ISegment from '../../models/ISegment';
import ReportHTTP from '../../services/http/reportHttp';

class Forecasting extends Component<{ company: ICompany }, { isRequesting: boolean, segments: ISegment[] }> {
  httpService: ReportHTTP;
  constructor(props: any) {
    super(props)
    this.httpService = new ReportHTTP();
    this.state = {
      isRequesting: false,
      segments: []
    }
  }

  componentDidMount(){
    this.getForecastingData(this.props.company);
  }

  getForecastingData(company: ICompany){
    this.httpService.getForecastingData(company).then(res => {
      this.setState({
        segments: res
      });
    }).catch(err => console.log(err));
  }
  
  render() {
    let data = {
      labels: this.state.segments.map(seg => seg.name),
      datasets: [
        {
          data: this.state.segments.map(seg => seg.percentage),
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ]
        }]
    }

    return (
      <div className="Forecasting">
        <h2 className="header">Forecasting</h2>
        <div className="chart"></div>
        <Fragment>
          <LoadingCircle showSpinner={this.state.isRequesting} />
          {
            !this.state.isRequesting ?
              this.state.segments.length > 0 ?
                <Line data={data} />
                : 'No data'
              : ''
          }
        </Fragment>
      </div>
    )
  }
}

export default Forecasting;