import LoginManager from '../authentication/login-manager';

class HttpManager {
  private httpManager: HttpManager | undefined;
  private loginManager: LoginManager| undefined;
  static SERVER_PROTOCOL = `http://`
  static SERVER_HOST = `localhost:9000/api/v1/`
  instance(){
    if(!this.httpManager){
      this.httpManager = new HttpManager();
      this.loginManager = new LoginManager();
    }
    return this.httpManager;
  }

  static attachHeaders(): Headers{
    var returnObj: Headers = new Headers();
    let token = LoginManager._retrieveLoginToken();
    if(token){
      returnObj.append('Authorization', `Basic ${token}`);
    }
    returnObj.append('Content-Type', 'application/json');
    return returnObj;
  }

}

export default HttpManager