import React, {Component} from 'react';
import {Row, Col, Card} from 'react-materialize';
import IStaff from '../../models/IStaff';

class StaffSummary extends Component<{staff: IStaff}, {}> {
  render(){
    return (
      <Col m={3} s={12}>
      <Card
        className="grey lighten-3"
        textClassName="black-text"
        title={this.props.staff.name}
        header={<img src={this.props.staff.imageSrc} className="card-image"/>}
      >
          <div className="card-team-title" >{this.props.staff.position}</div>
          <div className="card-team-description" >{this.props.staff.summary}</div>
      </Card>
    </Col>
    )
  }
}

export default StaffSummary;