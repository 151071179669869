import React, { Component } from 'react';
import './market-studies.scss';
import {Collapsible, CollapsibleItem, Row, Col, Card, Tabs, Tab} from 'react-materialize';
import ProductPackages from '../../components/product-packages/product-packages';

class MarketStudies extends Component<{}, {}> {

  render() {
    return (
      <div className="market-studies-container">
        <section className="gga-section jumbotron-small">
          <div className="jumbotron-header">
            <h1>Discover Your Market!</h1>
            <h3>The Who, What, When, Where, and Why of Buying Cannabis</h3>
          </div>
        </section>
        <section className="gga-section section-2">
          <h2>What Is a Market Study?</h2>
          <p>A market study combines a random sample of cannabis consumers in your area with an analysis of economic, demographic, and competitive data. The result is a complete picture of the cannabis market you operate in: who is buying, what they are buying, why they are buying, where they are buying, and why they are buying there.</p>
          <p>Here's how the process works.</p>
          <div className="z-depth-1 tab-margins">
            <Tabs className="tab">
              <Tab title="1. Define Your Goals" active>
                <div className="tab-paragraph"><p>Most companies approach a market study with specific questions they need answered, so the process begins with an extensive discussion about your business and your goals for the project.</p></div>
              </Tab>
              <Tab title="2. Consumer Survey">
                <div className="tab-paragraph"><p>After the data collection process has been customized, we conduct a survey of cannabis consumers in your area, asking questions about brand awareness and impressions, consumption and purchasing behavior, product preferences, demographics, and more.</p></div>
              </Tab>
              <Tab title="3. Economic Analysis">
                <div className="tab-paragraph"><p>At the same time, we begin collecting secondary data for your region. This includes competitive data, such as the location, reputation, size, and other characteristics of potential competitors, and demographic data, such as age, income, employment, population, and cannabis use prevalence.</p></div>
              </Tab>
              <Tab title="4. Put It Together">
                <div className="tab-paragraph"><p>Finally, results from the survey are combined with the secondary data to generate detailed estimates of total market potential, your and your competitors’ market share, distribution of customer segments, product and provider preferences, purchasing behaviors, consumption patterns, and anything else you may interested in.</p></div>
              </Tab>
            </Tabs>
          </div>
        </section>
        <section className="gga-section section-3">
          <h2>What Questions Can a Market Study Answer?</h2>
          <p>An appropriately-designed market study can answer a wide variety of questions, and we would welcome the opportunity to work with you on a new and exciting topic. However, most of the market studies we have conducted center around the following questions.</p>
          <Collapsible>
            <CollapsibleItem header="Who is buying cannabis?" icon="add" expanded="true">
              <p>It’s a big big world out there, and lots of people buy cannabis. In many states, the black market remains larger than the legal market, and makeup of cannabis consumers in Denver is different than in Cape Cod. What profiles best match consumers in your market?</p>
            </CollapsibleItem>
            <CollapsibleItem header="What cannabis products are they buying, and why?" icon="add">
              <p>Microdosing, whole plant extracts, CBD-rich, or just plain flower? Knowing what types of products consumers are looking for, now and in the future, is critical for your cannabis business. Historical sales data excludes the sizeable black market, and equally important is why consumers are (or are not) buying these products. How many consumers in your area avoid edibles because they take too long? Do they buy flower because they just like smoking from a bong?</p>
            </CollapsibleItem>
            <CollapsibleItem header="Where are they buying cannabis from, and why?" icon="add">
              <p>Knowing your market share is the first step to improving it. About half of black market consumers won’t pay more than a 20% premium to buy legally - what makes up the other half? If your biggest competitors are other legal providers, why do their customers choose them, and what can you do to change the equation?</p>
            </CollapsibleItem>
            <CollapsibleItem header="What customer segments or opportunities am I missing?" icon="add">
              <p>What percentage of consumers in your neighborhood will make their buying decisions based on price alone? Do you want to target this segment, or are you trying to move upmarket? Our detailed consumer profiling can help you identify ways to expand your customer base or target new, more desirable customer segments.</p>
            </CollapsibleItem>
            <CollapsibleItem header="Have my marketing or outreach efforts made an impact?" icon="add">
              <p>When consumers think about your brand, what comes to mind? How did they hear about you, and why did they first make the decision to buy? Do they remember your ads, or your competitor’s? Knowing the impact of your marketing, social media, and other efforts help you put money where it will make the biggest difference.</p>
            </CollapsibleItem>
            <CollapsibleItem header="What coming changes or new trends should I be aware of?" icon="add">
              <p>Studying data from the past is not always the best way to look forward, and the copycat approach does not help you get ahead. Instead, why not find out what consumers in your area are looking for next by asking them? Our focus on the cannabis industry gives us a broad picture of what is new, and combining that knowledge with feedback from consumers in your area will ensure you are always up-to-date.</p>
            </CollapsibleItem>
          </Collapsible>
        </section>
        <section className="gga-section section-4">
          <ProductPackages />
        </section>
      </div>
    );
  }
}

export default MarketStudies;
