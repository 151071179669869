import React, { Component } from 'react';
import './create-blog.scss';
import CKEditor from 'react-ckeditor-component';
import BlogHTTP from '../../services/http/blogHttp';
import IBlog from '../../models/IBlog';
import { withRouter, RouteComponentProps } from 'react-router';
import { UserContext } from '../../contexts/userContext';


class CreateBlog extends Component<RouteComponentProps<{}>, { title: string, content: string, imageUrl: string }> {
  httpService: BlogHTTP;

  constructor(props: any) {
    super(props);
    this.httpService = new BlogHTTP();
    this.state = {
      title: '',
      content: '',
      imageUrl: ''
    }
    this.saveBlog = this.saveBlog.bind(this);
    this.publishBlog = this.publishBlog.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(evt: any) {
    console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent
    })
  }

  onBlur(evt: React.FormEvent) {
    console.log("onBlur event called with event info: ", evt);
  }

  afterPaste(evt: React.FormEvent) {
    console.log("afterPaste event called with event info: ", evt);
  }

  saveBlog() {
    var blog: IBlog = {
      title: this.state.title,
      summary: this.state.content,
      isPublished: false
    }
    this.httpService.createBlog(blog).then(res => {
      this.props.history.push(`/blog/${res.path}`);
    });
  }

  publishBlog() {
    var blog: IBlog = {
      title: this.state.title,
      summary: this.state.content,
      isPublished: true
    }
    this.httpService.createBlog(blog).then(res => {
      this.props.history.push(`/blog/${res.path}`);
    });
  }

  handleImageChange(e: any) {
    console.log(e);

    this.setState({
      imageUrl: e.target.files
    });

  }

  handleImageSubmit(){
    console.log(this.state.imageUrl);
  }
  render() {
    return (
      <UserContext.Consumer>
        {({ isAdmin, navigateToPage }) => (
          isAdmin ?
            <div className="create-blog-container">
              <div className="create-blog-row input-field">
                <input type="text" value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} />
                <label>Title</label>
              </div>
              <div className="create-blog-row">
                <form onSubmit={this.handleImageSubmit }>
                  <label>
                    Image path
                    <input type="file" value={this.state.imageUrl} onChange={this.handleImageChange}/>
                  </label>
                  <input type="submit" value="Submit" />
                </form>
                <CKEditor
                  activeClass="p10"
                  content={this.state.content}
                  events={{
                    "blur": this.onBlur,
                    "afterPaste": this.afterPaste,
                    "change": this.onChange
                  }}
                />
              </div>
              <div className="create-blog-row btn-container">
                <button className="btn" onClick={this.saveBlog}>Save</button>
                <button className="btn" onClick={this.publishBlog}>Publish</button>
              </div>
            </div>
            : navigateToPage('blogs')
        )}
      </UserContext.Consumer>
    )
  }
}

export default withRouter(CreateBlog);