import React, { Component, Fragment } from 'react';
import './dashboard.scss';
import { UserContext } from '../../contexts/userContext';
import CompetitiveOverview from '../../components/competitive-overview/competitive-overview';
import { CompanyContext } from '../../contexts/companyContext';
import Awareness from '../../components/awareness/awareness';
import IPlots from '../../models/IPlots';
import ISegment from '../../models/ISegment';
import IAnalysis from '../../models/IAnalysis';
import PricingAnalysis from '../../components/pricing-analysis/pricing-analysis';
import ProductPortfolio from '../../components/product-portfolio/product-portfolio';
import ReportHTTP from '../../services/http/reportHttp';
import ICompany from '../../models/ICompany';


function Dashboard(props: any) {
  return (
    <Fragment>
      <UserContext.Consumer>
        {({ isAdmin, navigateToPage }) => (
          isAdmin ?
            <CompanyContext.Consumer>
              {({ companies }) => (
                <InnerDashboard companies={companies} />
              )}
            </CompanyContext.Consumer>
            : ''
        )}
      </UserContext.Consumer>
    </Fragment>
  )
}

class InnerDashboard extends Component<{ companies: ICompany[] }, { isRequesting: boolean, competitors: IAnalysis[], client: IAnalysis | null, companies: ICompany[] }> {
  reportHttp: ReportHTTP
  constructor(props: any) {
    super(props);
    this.reportHttp = new ReportHTTP();
    this.state = {
      isRequesting: false,
      competitors: [],
      client: null,
      companies: []
    }
  }

  componentDidMount() {
    let company = this.props.companies[0];
    this.getAnalysis(company);
  }
  async getAnalysis(company: ICompany) {
    let competitors = await this.reportHttp.getCompetitorReports(company)
    let client = await this.reportHttp.getCompanyReport(company);
    this.setState({
      competitors,
      client
    });
  }
  render() {
    return (
      <div className="Dashboard">
        <article className="competitive-overview">
          <CompetitiveOverview competitors={this.state.competitors} />
        </article>
        <article className="awareness">
          <Awareness competitors={this.state.competitors} client={this.state.client} />
        </article>
        <article className="pricing-analysis">
          <PricingAnalysis competitors={this.state.competitors} client={this.state.client} />
        </article>
        <article className="product-portfolio">
          <ProductPortfolio competitors={this.state.competitors} client={this.state.client} /></article>
      </div>
    );
  }
}

export default Dashboard;
