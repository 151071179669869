import React, { Component, Fragment } from 'react';
import './competitive-overview.scss';
import ICompany from '../../models/ICompany';
import ICompetitor from '../../models/ICompetitor';
import LoadingCircle from '../loading-circle/loading-circle';
import IAnalysis from '../../models/IAnalysis';

class CompetitiveOverview extends Component<{ competitors: IAnalysis[] }, {  isRequesting: boolean, index: number }> {


  constructor(props: any) {
    super(props);
    this.state = {
      isRequesting: false,
      index: -1
    }
  }

  componentDidMount() {
    this.getCompetitors()
  }
  getCompetitors() {
    //Make sure set index above -1
    this.setState({
      index: 0
    })
  }

  changeCompetitiveOverview(index: number){
    this.setState({
      index: index
    });
  }
  render() {
    let competitors = this.props.competitors.slice(0, 4);
    return (
      <div className="CompetitiveOverview">
        <h2 className="header">Competitive Overview</h2>
        {
          this.state.isRequesting ?
            <LoadingCircle showSpinner={this.state.isRequesting} />
            : competitors.length > 0 ?
              <Fragment>
                <div className="competitor-info">{competitors[this.state.index].overview}</div>
                <ul className="list">
                  {
                    competitors.map((competitor, i) => <li key={i} onClick={()=> this.changeCompetitiveOverview(i)} className={this.state.index === i? 'active list-item':'list-item' }>{competitor.name}</li>)
                  }
                </ul>
              </Fragment>
              : 'No data'
        }
      </div>
    )
  }
}
export default CompetitiveOverview;