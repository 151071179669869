import React, { Component } from 'react';
import './footer.scss';

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="footer-container">
          <div className="social-icons">
            <img alt="Facebook Logo" src="/assets/images/FacebookLogo--Black.svg" />
            <img alt="Twitter Logo" src="/assets/images/TwitterLogo--Black.svg" />
            <img alt="Linkedin Logo" src="/assets/images/LinkedinLogo--Black.png" />
          </div>
          <div className="footer-body">
            <div className="footer-item-container">Blog</div>
            <div className="footer-item-container">About</div>
            <div className="footer-item-container">Contact Us</div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
