import fire from './firebase-initialize';
import IUser from '../../models/IUser';
import UserHttp from '../http/userHttp';
import IFireUser from '../../models/IFireUser';

export default class LoginManager {
  static TOKEN_NAME = 'TOKEN';
  userHttp: UserHttp

  constructor(){
    this.userHttp = new UserHttp();
  }
  _auth: firebase.auth.Auth = fire.auth();
  _loginWithEmail(username: string, password: string) {
    return new Promise((resolve, reject) => {
      this._auth.signInWithEmailAndPassword(username, password).then(user => {
        if(user){
          resolve(user);
        } else {
          reject();
        }
      }).catch(error => reject(error));
    })
  }

  _createUser(user: IFireUser) {
    return new Promise((resolve, reject) => {
      this._auth.createUserWithEmailAndPassword(user.email, user.password).then(firebaseUser => {
        if(!firebaseUser){
          reject();
        }
        if(firebaseUser.user){
          let addedUser: IUser = {
            id: firebaseUser.user.uid,
            role: 5,
            isActive: true
          }
          this.userHttp.createUser(addedUser).then(added => resolve(user)).catch(err => resolve(user));
          // resolve(user)
        } else {
          resolve(user);
        }
      }).catch(error => reject(error));
    })
  }
  _signOut() {
    return new Promise((resolve, reject) => {
      this._auth.signOut().then(() => resolve({})).catch(error => reject(error));
    })
  }

  static _storeLoginToken(token: string) {
    localStorage.setItem(LoginManager.TOKEN_NAME, token);
  }

  static _retrieveLoginToken(): string | null {
    return localStorage.getItem(LoginManager.TOKEN_NAME);
  }

  static _removeLoginToken() {
    localStorage.removeItem(LoginManager.TOKEN_NAME);
  }
}