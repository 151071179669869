import React from 'react';
import IUser from '../models/IUser';

var user: { isAdmin: boolean, authenticated: boolean, user: IUser | null, navigateToPage: any } = {
  isAdmin: false,
  authenticated: false,
  user: null,
  navigateToPage: (page: string) => { }
}
export const UserContext = React.createContext(
  user
);