import React, { Component } from 'react';
import './about-us.scss';
import {Row, Col, Card} from 'react-materialize';
import StaffSummary from '../../components/staff-summary/staff-sumnmary';
import IStaff from '../../models/IStaff';

class AboutUs
 extends Component<{}, {staffList:IStaff[]}> {
   
  constructor(props: any){
    super(props);
    let staffList:IStaff[] = [
      {
        name: "Ben",
        position: "Lead Analyst",
        summary: "Ben has been working in the industry for years.",
        imageSrc: "/assets/images/customer-survey.png"
      },
      {
        name: "Bruce",
        position: "Research Coordinator",
        summary: "Bruce has been working in the industry for years.",
        imageSrc: "/assets/images/customer-survey.png"
      },
      {
        name: "Colby",
        position: "Market Specialist",
        summary: "Colby has been working in the industry for years.",
        imageSrc: "/assets/images/customer-survey.png"
      },
      {
        name: "Jackson",
        position: "Lead Programmer",
        summary: "Jackson has been working in the industry for years.",
        imageSrc: "/assets/images/customer-survey.png"
      },
      {
        name: "Rosa",
        position: "Data Engineer",
        summary: "Rosa has been working in the industry for years.",
        imageSrc: "/assets/images/customer-survey.png"
      },
      {
        name: "Sarah",
        position: "Research Analyst",
        summary: "Sarah has been working in the industry for years.",
        imageSrc: "/assets/images/customer-survey.png"
      }
    ]

    this.state = {
      staffList: staffList
    }
  }

  render() {
    let list = this.state.staffList != null ? this.state.staffList.map((staff, i) => <StaffSummary key={i} staff={staff} />) : []
    return (
      <div className="about-us-container">
        <div className="divider"></div>
        <section className="gga-section section-2">
        	<h2>About Green Growth Analytics</h2>
          <p>Green Growth Analytics was founded to being the same high-quality market research available in other industies to cannabis retailers, brands, and service providers.</p>
          <p>Customer segmentation and profiling forms the basis of any market research effort. For example, only one in ten recreational cannabis customers regularly purchases topicals and creams. But they form a distinctive group that also favors CBD-rich products and edibles, has a much higher income, wants a large product selection, and almost never buy concentrates or pre-rolls.</p>
        </section>
        <div className="divider"></div>
        <section className="gga-section section-3">
          <h2>Meet the Team!</h2>
	        <Row>
            {list}
          </Row>
        </section>
      </div>
    );
  }
}

export default AboutUs
;
