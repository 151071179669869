import HttpManager from "./httpManager";
import ICompany from "../../models/ICompany";

class CompanyHTTP {
  static ROOT_URL = "company/";
  getHeaders(): {} {
    var headers = HttpManager.attachHeaders();
    return headers;
  }
  getCompanys(): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = `${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${CompanyHTTP.ROOT_URL}`;
      fetch(url,
        {
          headers: this.getHeaders(),
          method: 'GET'
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  getCompany(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!id) {
        reject({});
      }
      console.log(id);
      let url = `${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${CompanyHTTP.ROOT_URL}${id}`;
      fetch(url,
        {
          headers: this.getHeaders(),
          method: 'GET'
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  createCompany(company: ICompany): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${CompanyHTTP.ROOT_URL}`,
        {
          headers: this.getHeaders(),
          method: 'POST',
          body: JSON.stringify(company)
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  editCompany(company: ICompany): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${CompanyHTTP.ROOT_URL}${company.id}`,
        {
          headers: this.getHeaders(),
          method: 'PUT',
          body: JSON.stringify(company)
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }

  deleteCompany(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!id) {
        reject({});
      }
      fetch(`${HttpManager.SERVER_PROTOCOL}${HttpManager.SERVER_HOST}${CompanyHTTP.ROOT_URL}${id}`,
        {
          headers: this.getHeaders(),
          method: 'DELETE'
        }).then(res => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject({})
          }
        }).catch(err => reject(err));
    });
  }
}

export default CompanyHTTP;