import React, { Component, Props, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.scss';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import Landing from './pages/landing/landing';
import Membership from './pages/membership/membership';
import ContactUs from './pages/contact-us/contact-us';
import Whitepages from './pages/whitepages/whitepages';
import CustomerSurveys from './pages/customer-surveys/customer-surveys';
import MarketStudies from './pages/market-studies/market-studies';
import AboutUs from './pages/about-us/about-us';
import Login from './pages/login/login';
import LoginManager from './services/authentication/login-manager';
import ProtectedRouter from './components/protected-routes/protectedRouter';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { User } from 'firebase';
import SignUp from './pages/sign-up/sign-up';
import Blog from './pages/blogs/blog';
import EditBlog from './pages/blogs/edit-blog';
import UserHTTP from './services/http/userHttp';
import { UserContext } from './contexts/userContext';
import { CompanyContext } from './contexts/companyContext';
import IUser from './models/IUser';
import Blogs from './pages/blogs/blogs';
import CreateBlog from './pages/blogs/create-blog';
import Dashboard from './pages/dashboard/dashboard';
import NoMatch from './pages/no-match/no-match';
import Reports from './pages/reports/reports';
import Cannapanel from './pages/cannpanel/cannapanel';
import CompanyHTTP from './services/http/companyHttp';
import ICompany from './models/ICompany';

class App extends Component<RouteComponentProps<{}>, { authenticated: boolean, isMenuCollapsed: boolean, isAdmin: boolean, user: IUser | null, companies: ICompany[], isRequestingCompanies: boolean }> {
  loginManager: LoginManager = new LoginManager()
  userHttp: UserHTTP
  companyHttp: CompanyHTTP

  constructor(props: any) {
    super(props);
    this.userHttp = new UserHTTP();
    this.companyHttp = new CompanyHTTP();
    this.state = {
      authenticated: false,
      isMenuCollapsed: true,
      isAdmin: false,
      user: null,
      companies: [],
      isRequestingCompanies: false
    }
    this.closeMenu = this.closeMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    this.loginManager._auth.onAuthStateChanged(((user: User) => {
      if (user) {
        if (this.props.location.pathname === '/login') {
          this.props.history.push('/cannapanel');
        }
        user.getIdToken().then(token => {
          LoginManager._storeLoginToken(token);
          this.setState({
            authenticated: true,
            isAdmin: false,
            user: null
          });
          this.userHttp.getUser().then(authUser => {
            let isAdmin = authUser.data.role < 2 ? true : false;
            this.setState({
              isAdmin: isAdmin,
              user: Object.assign({}, authUser, { name: user.displayName, email: user.email, emailVerified: user.emailVerified })
            });
          }).catch(err => {
            this.setState({
              isAdmin: false,
              user: null
            });
          });
          this.setState({
            isRequestingCompanies: true
          });
          this.companyHttp.getCompanys().then(res => {
            this.setState({
              companies: res.data,
              isRequestingCompanies: false
            });
          }).catch(err => {
            this.setState({
              companies: [],
              isRequestingCompanies: false
            });
          })
        }).catch(err => {
          this.setState({
            authenticated: false,
            isAdmin: false,
            user: null
          });
          this.loginManager._signOut();
        });
      } else {
        let token = LoginManager._retrieveLoginToken();
        //TODO: undo
        this.setState({
          authenticated: false,
          isAdmin: false,
          user: null
        });
        if (token) {
          LoginManager._removeLoginToken();
          this.props.history.push('/login');
        }
      }
    }).bind(this));
  }


  closeMenu() {
    this.setState({
      isMenuCollapsed: true
    });
  }

  toggleMenu() {
    this.setState({
      isMenuCollapsed: !this.state.isMenuCollapsed
    });
  }

  render() {
    return (
      <div className="app-container" onClick={() => !this.state.isMenuCollapsed ? this.toggleMenu() : ''}>
        <UserContext.Provider value={{
          isAdmin: this.state.isAdmin,
          authenticated: this.state.authenticated, user: this.state.user,
          navigateToPage: (page: string) => { this.props.history.push(`/${page}`) }
        }}>
          <CompanyContext.Provider value={{ companies: this.state.companies, isRequesting: this.state.isRequestingCompanies }}>
            <Header authenticated={this.state.authenticated} loginManager={this.loginManager} isMenuCollapsed={this.state.isMenuCollapsed} toggleHeader={this.toggleMenu} />
            <main>
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/customer-surveys" component={CustomerSurveys} />
                <Route exact path="/market-studies" component={MarketStudies} />
                <Route exact path="/membership" component={Membership} />
                <Route strict exact path="/blogs" component={Blogs} />
                <Route exact path="/blog/:blogId" component={Blog} />
                <Route exact path="/sign-up" component={SignUp} />
                <Route exact path="/contact-us" component={ContactUs} />
                <Route exact path="/about-us" component={AboutUs} />
                <Route exact path="/login" component={() => <Login loginManager={this.loginManager} />} />
                {
                  this.state.isAdmin ?
                    <Fragment>
                      <Route exact path="/dashboard" component={Dashboard} />
                      <Route exact path="/reports" component={Reports} />
                      <Route exact path="/cannapanel" component={Cannapanel} />
                      <Route exact path="/blogs/create" component={CreateBlog} />
                      <Route exact path="/blog/:blogId/edit" component={EditBlog} />
                    </Fragment>
                    : ''
                }
                <Route component={NoMatch} />
              </Switch>
            </main>
            <Footer />
          </CompanyContext.Provider>
        </UserContext.Provider>
      </div>
    );
  }
}

export default withRouter(App);
