import React, { Component } from 'react';
import './contact-us.scss';
import IMailHTTP from '../../services/http/mailHttp';
import IMail from '../../models/IMail';
import {TextInput } from 'react-materialize';

class ContactUs extends Component<{isAdmin: boolean}, {name: string, email: string, subject: string, message: string, nameError: boolean, emailError: boolean, subjectError: boolean, messageError: boolean}> {

  httpService: IMailHTTP;
  constructor(props: any){
    super(props);
    this.httpService = new IMailHTTP();
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      nameError: false,
      emailError: false,
      messageError: false,
      subjectError: false
    }
    this.sendMessage = this.sendMessage.bind(this);
  }

  sendMessage(){
    let email: IMail = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      body: this.state.message
    }
    this.httpService.createIMail(email).then(_ => {
      console.log("successful")
    }).catch(err => {
      console.log("unsucessful")
    })
  }

  render() {
    return (
      <div className="gga-section contact-container">
        <section className="gga-section jumbotron-small">
          <div className="jumbotron-header">
            <h2>Get in Touch!</h2>
          </div>
        </section>
        <section className="gga-section section-2">
          <div className="contact-us-alt">
            <h2>Call or email us</h2>
            <a href="mailto:info@greengrowth.io" className="contact-us-item">
              <i className="fa fa-envelope-o"></i>
              <span>Email: info@greengrowth.io</span>
            </a>
            <a href="tel:13026486994" className="contact-us-item">
              <i className="fa fa-phone"></i>
              <span>Phone: +1 302 648 6994</span>
            </a>
          </div>
          <form className="card card--email">
            <h2>Reach out to us!</h2>
            <div className="form-group input-field">
              <input type="text" value={this.state.name} onChange={e => this.setState({name: e.target.value})} />
              <label className={this.state.name.length > 0 ? "active" : ''}>Your name</label>
            </div>
            <div className="form-group input-field">
              <input type="email" value={this.state.email} onChange={e => this.setState({email: e.target.value})}  />
              <label className={this.state.email.length > 0 ? "active" : ''}>Your email</label>
            </div>
            <div className="form-group input-field">
              <input type="text" value={this.state.subject} onChange={e => this.setState({subject: e.target.value})} />
              <label className={this.state.subject.length > 0 ? "active" : ''}>Your subject</label>
            </div>
            <div className="form-group input-field">
              <textarea className="form-body materialize-textarea" name="message"  value={this.state.message} onChange={e => this.setState({message: e.target.value})} ></textarea>
              <label className={this.state.message.length > 0 ? "active" : ''}>Message Body</label>
            </div>
            <button type="submit" className="btn" onClick={this.sendMessage}>Send message</button>
          </form>
        </section>
      </div>
    );
  }
}

export default ContactUs;
