import React, { Component } from 'react';
import './blog.scss';
import BlogHTTP from '../../services/http/blogHttp';
import LoadingCircle from '../../components/loading-circle/loading-circle';
import { RouteComponentProps, withRouter } from 'react-router';
import IBlog from '../../models/IBlog';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext';

class Blog extends Component<RouteComponentProps<{ blogId: string }> & {isAdmin: boolean}, { isRequesting: boolean }> {
  httpService: BlogHTTP;
  model?: IBlog;
  constructor(props: any) {
    super(props);
    this.httpService = new BlogHTTP()
    this.deleteBlog = this.deleteBlog.bind(this);
    this.publishBlog = this.publishBlog.bind(this);
    this.getBlog = this.getBlog.bind(this);
    this.state = {
      isRequesting: false
    }
  }
  componentDidMount() {
    let id = this.props.match.params.blogId;
    this.getBlog(id);
  }

  getBlog(id: string) {
    this.setState({
      isRequesting: true
    });
    this.httpService.getBlog(id).then(res => {
      console.log(res)
      this.model = res.data as IBlog;
      this.setState({
        isRequesting: false
      });
    }).catch(err => { console.log(err); this.props.history.push('/404') });
  }

  deleteBlog() {
    console.log(this.props);
    let id = this.props.match.params.blogId;
    this.httpService.deleteBlog(id).then(_ => {
      this.props.history.push('/blogs');
    }).catch(err => console.log(err));
  }

  publishBlog() {
    let isPublished = this.model ? this.model.isPublished : false;
    var blog: IBlog = {
      path: this.props.match.params.blogId,
      isPublished: !isPublished
    }
    this.httpService.editBlog(blog).then(res => {
      console.log(res);
      this.props.history.push(`/blogs`);
    }).catch(err => console.log(err));
  }

  render() {
    let summary = this.model ? this.model.summary ? this.model.summary : '' : '';
    let publishText = this.model ? this.model.isPublished ? 'Unpublish' : 'Publish' : ''
    return (
      <div className="Blog">
        {this.model ? 
          <div>
            <UserContext.Consumer>
            {({ isAdmin }) => (
              isAdmin ?
            <div>
              <Link to={`${this.props.match.url}/edit`}>Edit</Link>
              <button onClick={this.deleteBlog} className="btn">Delete</button>
              <button onClick={this.publishBlog} className="btn">{publishText}</button>
            </div>
              : ''
            )}
          </UserContext.Consumer>
            <h2>{this.model.title}</h2>
            <h4>{this.model.author}</h4>
            <div dangerouslySetInnerHTML={{ __html: summary }}></div>
            <p>{this.model.dateCreated}</p>
          </div> : ''
        }
        <LoadingCircle showSpinner={this.state.isRequesting} />
      </div>
    );
  }
}

export default withRouter(Blog);
