import React, { Component } from 'react';
import './edit-blog.scss';
import CKEditor from 'react-ckeditor-component';
import BlogHTTP from '../../services/http/blogHttp';
import IBlog from '../../models/IBlog';
import { withRouter, RouteComponentProps } from 'react-router';
import { UserContext } from '../../contexts/userContext';


class EditBlog extends Component<RouteComponentProps<{ blogId: string }> & { title?: string, summary?: string, isAdmin: boolean }, { title: string, content: string, isRequesting: boolean }> {
  httpService: BlogHTTP;

  constructor(props: any) {
    super(props);
    this.httpService = new BlogHTTP();
    if (this.props.title && this.props.summary) {
      this.state = {
        title: this.props.title,
        content: this.props.summary,
        isRequesting: false
      }
    } else {
      this.state = {
        title: '',
        content: '',
        isRequesting: false
      }
    }
    this.saveBlog = this.saveBlog.bind(this);
    this.deleteBlog = this.deleteBlog.bind(this);
    this.onChange = this.onChange.bind(this);
  }


  componentDidMount() {
    let id = this.props.match.params.blogId;
    console.log(id);
    this.getBlog(id);
  }

  getBlog(id: string) {
    this.setState({
      isRequesting: true
    });
    this.httpService.getBlog(id).then(res => {
      let model = res.data as IBlog;
      this.setState({
        title: model.title? model.title : '',
        content: model.summary ? model.summary : '',
        isRequesting: false
      });
    }).catch(err => { this.props.history.push('/404') });
  }

  onChange(evt: any) {
    console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent
    })
  }

  onBlur(evt: React.FormEvent) {
    console.log("onBlur event called with event info: ", evt);
  }

  afterPaste(evt: React.FormEvent) {
    console.log("afterPaste event called with event info: ", evt);
  }

  saveBlog() {
    var blog: IBlog = {
      path: this.props.match.params.blogId,
      title: this.state.title,
      summary: this.state.content,
      isPublished: false
    }
    this.httpService.editBlog(blog).then(res => {
      this.props.history.push(`/blogs`);
    }).catch(err => console.log(err));
  }

  deleteBlog() {
    console.log(this.props);
    let id = this.props.match.params.blogId;
    this.httpService.deleteBlog(id).then(_ => {
      this.props.history.push('/blogs');
    }).catch(err => console.log(err));
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ isAdmin, navigateToPage }) => (
          isAdmin ?
            <div className="edit-blog-container">
              <div className="edit-blog-row input-field">
                <input type="text" value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} />
                <label className={this.state.title.length > 0 ? 'active': ''}>Title</label>
              </div>
              <div className="edit-blog-row">
                <CKEditor
                  activeClass="p10"
                  content={this.state.content}
                  events={{
                    "blur": this.onBlur,
                    "afterPaste": this.afterPaste,
                    "change": this.onChange
                  }}
                />
              </div>
              <div className="edit-blog-row btn-container">
                <button className="btn" onClick={this.saveBlog}>Save</button>
                <button className="btn" onClick={this.deleteBlog}>Delete</button>
              </div>
            </div>
            : navigateToPage('404')
        )}
      </UserContext.Consumer>
    )
  }
}

export default withRouter(EditBlog);