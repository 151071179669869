import React, { Component } from 'react';
import './blogs.scss';
import BlogHTTP from '../../services/http/blogHttp';
import LoadingCircle from '../../components/loading-circle/loading-circle';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import BlogPostSummary from '../../components/blog-post-summary/blog-post-summary';
import { UserContext } from '../../contexts/userContext';

class Blogs extends Component<RouteComponentProps<{}>, { isRequesting: boolean, isError: boolean }>{
  httpService: BlogHTTP;
  blogsList = [];
  constructor(props: any) {
    super(props);
    this.httpService = new BlogHTTP()
    this.state = {
      isRequesting: false,
      isError: false
    }
  }
  componentDidMount() {
    this.getBlogs()
  }
  getBlogs() {
    this.setState({
      isRequesting: true,
    });
    this.httpService.getBlogs().then(res => {
      this.blogsList = res.data;
      this.setState({
        isRequesting: false,
        isError: false
      });
    }).catch(err => {
      this.setState({
        isRequesting: false,
        isError: true
      });
    });
  }
  render() {
    let blogElements = this.blogsList.map((blog, i) => <BlogPostSummary key={i} blog={blog} />);
    return (
      <div className="blogs-container">
        Blogs Page
      <UserContext.Consumer>
          {({ isAdmin }) => (
            isAdmin ? <Link className="btn" to={`${this.props.match.path}/create`}>Create new blog</Link> : ''
          )}
        </UserContext.Consumer>
        <div className="blogs-element-container">
          {blogElements}
        </div>
        {
          this.state.isRequesting ?
            <LoadingCircle showSpinner={this.state.isRequesting} />
            : ''
        }
      </div>
    );
  }
}

export default withRouter(Blogs);
