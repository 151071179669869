import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './membership.scss';

class Membership extends Component {
  render() {
    return (
      <div className="gga-section membership-container">
        <section className="gga-section jumbotron-small">
          <div className="jumbotron-header">
            <h1>Actionable Market Intelligence</h1>
            <h2>Without the High Price Tag</h2>
          </div>
        </section>
        <section className="gga-section section2">
          <h2>Green Growth Membership</h2>
          <p>Membership packages provide access to monthly, quarterly, and annual reporting on sales, consumers, trends, and more in the cannabis marketplace.</p>
          <p>More information on Green Growth Membership coming soon!</p>
        </section>
      </div>
    );
  }
}

export default Membership;
