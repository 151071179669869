import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import './header.scss';
import LoginManager from '../../services/authentication/login-manager';
import { functions } from 'firebase';
import { UserContext } from '../../contexts/userContext';
import { withRouter, RouteComponentProps } from 'react-router';

class Header extends Component<RouteComponentProps<{}> & { authenticated: boolean, loginManager: LoginManager, isMenuCollapsed: boolean, toggleHeader: any }, { headerList: any[] }> {

  constructor(props: any) {
    super(props);
    this.state = {
      headerList: []
    }
  }

  signOut() {
    this.props.loginManager._signOut();
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ authenticated, isAdmin }) => (
          <header>
            <nav className="navbar navbar-default">
              <div className="navbar-header">
                <NavLink to="/" className="navbar-brand">
                  <img
                    height="50px"
                    padding-top="5px"
                    src="/assets/images/logo.png"
                    alt="Green Growth Analytics Logo"
                  />
                  <div className="logo-title-container">
                    <img
                      height="60px"
                      src="/assets/images/logo-title.png"
                      alt="Green Growth Analytics Title"
                    />
                  </div>
                </NavLink>

                <button className="navbar-toggle" onClick={() => { this.props.toggleHeader() }}>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              </div>
              <div className={`nav-container ${this.props.isMenuCollapsed ? 'hidden' : ''}`}>
                <ul className="navbar-nav">
                  <li className={this.props.location.pathname === '/' ? 'selected' : ''}>
                    <NavLink to="/" className="nav-link">
                      <span>Home</span>
                    </NavLink>
                  </li>
                  <li className={this.props.location.pathname === '/about-us' ? 'selected' : ''}>
                    <NavLink to="/about-us" className="nav-link">
                      <span>About</span>
                    </NavLink>
                  </li>
                  <li className={this.props.location.pathname === '/customer-surveys' ? 'selected' : ''}>
                    <NavLink to="/customer-surveys" className="nav-link">
                      <span>Customer Surveys</span>
                    </NavLink>
                  </li>
                  <li className={this.props.location.pathname === '/market-studies' ? 'selected' : ''}>
                    <NavLink to="/market-studies" className="nav-link">
                      <span>Market Studies</span>
                    </NavLink>
                  </li>
                  <li className={this.props.location.pathname === '/membership' ? 'selected' : ''}>
                    <NavLink to="/membership" className="nav-link">
                      <span>Membership</span>
                    </NavLink>
                  </li>
                  <li className={this.props.location.pathname === '/contact-us' ? 'selected' : ''}>
                    <NavLink to="/contact-us" className="nav-link">
                      <span>Contact</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          </header>
        )}
      </UserContext.Consumer>
    );
  }
}

export default withRouter(Header);
