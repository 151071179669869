import React, { Component } from 'react';
import './product-portfolio.scss';
import IAnalysis from '../../models/IAnalysis';
import { Pie } from 'react-chartjs-2';

class ProductPortfolio extends Component<{ competitors: IAnalysis[], client: IAnalysis | null }, {}>{

  generateChart(competitor: IAnalysis) {
    var typeCount = {} as any;
    competitor.inventoryPricing.forEach(product => {
      if(!typeCount[product.type]){
        typeCount[product.type] = 0;
      }
      typeCount[product.type]++;
    })
    let data = {
      labels: Object.keys(typeCount).map(key => key),
      datasets: [
        {
          data: Object.keys(typeCount).map(key => typeCount[key]),
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56"
          ]
        }]
    };
    return (
      <div className="charts">
        <h3 className="title">{competitor.name}</h3>
        <Pie data={data} />
      </div>
    )
  }
  render() {
    let clientChart = this.props.client ? this.generateChart(this.props.client) : '';
    let competitorCharts = this.props.competitors.map(competitor => this.generateChart(competitor));
    return (
      <div className="ProductPortfolio">
        <h2 className="header">Product Portfolio</h2>
        {clientChart}
        {competitorCharts}
      </div>
    )
  }
}

export default ProductPortfolio;